import './App.css';
import { AllRoutes } from './Routes/AllRoutes';
import { Header, Footer } from './components';

function App() {

  return (
    <div className="App">
      <Header />
      <AllRoutes />
      <Footer />
    </div>
  );
}

export default App;


/*

To install Routes:
use command
-------------
-> npm install react-router-dom@6
-> npm install -D tailwindcss@3       @ 3 means version 3 
*/