
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Logo from '../assets/images/gw_favicon.ico';
import Logo2 from '../assets/images/gb_favicon.ico';

export const Header = () => {

     const [hidden, setHidden] = useState(true);
     // Add in Dark Mode Feature, Add darkMode in Config first
     //1. Create a Variable
     const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem('darkMode')) || false);
     const navigate = useNavigate();

     useEffect(() => {
          localStorage.setItem('darkMode', JSON.stringify(darkMode));
          if (darkMode) {
               document.documentElement.classList.add('dark');
          } else {
               document.documentElement.classList.remove('dark')
          }
     }, [darkMode]);

     const activeClass = 'text-base block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500';
     const inActiveClass = 'text-base block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700';


     const handleSubmit = (e) => {
          e.preventDefault();
          const queryTerm = e.target.search.value;
          e.target.reset();
          return navigate(`/search?q=${queryTerm}`);

     }

     /*
     Setting makes the header take the max width of the screen
     -> max-w-screen-xl
     */

     return (
          <header>
               <nav className="bg-white border-b-4 border-gray-200 px-2 sm:px-4 py-2 dark:bg-gray-900 dark:border-b-1 dark:border-gray-900">

                    <div className="lg:container flex flex-wrap justify-between mx-auto p-4">
                         <Link to="/" className="flex items-center">
                              <img src={darkMode ? Logo : Logo2} className="h-6 mr-3" alt="Ghost Purp Logo" />
                              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">G - Cinemate</span>
                         </Link>

                         <div id='mobile-nav' className="flex md:order-2">
                              <button onClick={() => setDarkMode(!darkMode)} data-tooltip-target="default-toggle-example-toggle-dark-mode-tooltip" type="button" data-toggle-dark="dark" className=" mr-3 flex items-center w-9 h-9 justify-center text-xs font-medium text-gray-700 bg-white border border-gray-200 rounded-lg toggle-dark-state-example hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-500 dark:bg-gray-800 focus:outline-none dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                   {darkMode ?
                                        (<svg className="w-4 h-4 text-gray-800 dark:text-white hover:text-sky-500 hover:fill-yellow-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 3V1m0 18v-2M5.05 5.05 3.636 3.636m12.728 12.728L14.95 14.95M3 10H1m18 0h-2M5.05 14.95l-1.414 1.414M16.364 3.636 14.95 5.05M14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
                                        </svg>
                                        ) :
                                        (
                                             <svg className="w-4 h-4 text-gray-800 dark:text-white hover:fill-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                                                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.509 5.75c0-1.493.394-2.96 1.144-4.25h-.081a8.5 8.5 0 1 0 7.356 12.746A8.5 8.5 0 0 1 8.509 5.75Z" />
                                             </svg>
                                        )}

                              </button>

                              <button onClick={() => setHidden(!hidden)} type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" className="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 mr-1" >
                                   <svg className="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                   </svg>
                                   <span className="sr-only">Search</span>
                              </button>

                              <div className="relative hidden md:block">
                                   <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="none" viewBox="0 0 20 20">
                                             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                        <span className="sr-only">Search icon</span>
                                   </div>
                                   <form onSubmit={handleSubmit}>
                                        <input type="text" id="search-navbar" name='search' className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." autoComplete='off' />
                                   </form>
                              </div>

                              <button onClick={() => setHidden(!hidden)} data-collapse-toggle="navbar-search" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-search" aria-expanded="false">
                                   <span className="sr-only">Open main menu</span>
                                   <svg className="w-5 h-5" aria-hidden="true" fill="none" viewBox="0 0 17 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                                   </svg>
                              </button>
                         </div>

                         <div id='nav-links' className={`${hidden ? 'hidden' : ""} items-center justify-between w-full md:flex md:w-auto md:order-1" id="navbar-search`}>
                              <div className="relative mt-3 md:hidden">
                                   <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="none" viewBox="0 0 20 20">
                                             <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                   </div>
                                   <form onSubmit={handleSubmit}>
                                        <input type="text" id="search-navbar" name='search' className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." autoComplete='off' />
                                   </form>
                              </div>
                              <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                                   <li>
                                        <NavLink to="/" className={({ isActive }) => isActive ? activeClass : inActiveClass} end>
                                             Home
                                        </NavLink>
                                   </li>
                                   <li>
                                        <NavLink to="/movies/popular" className={({ isActive }) => isActive ? activeClass : inActiveClass}>
                                             Popular
                                        </NavLink>
                                   </li>
                                   <li>
                                        <NavLink to="/movies/top" className={({ isActive }) => isActive ? activeClass : inActiveClass}>
                                             Top Rated
                                        </NavLink>
                                   </li>
                                   <li>
                                        <NavLink to="/movies/upcoming" className={({ isActive }) => isActive ? activeClass : inActiveClass}>
                                             Upcoming
                                        </NavLink>
                                   </li>
                              </ul>
                         </div>

                    </div>
               </nav>

          </header>
     )
}
